import React from 'react';
import Alert from '@mui/material/Alert';
import { Button, FeedbackButton } from '@dc3/ui/buttons';
import { Box } from '@dc3/ui/box';
import { Icon } from '@dc3/ui/icon';
import { Text } from '@dc3/ui/text';
import { useI18n } from '@dc3/utils/hooks';
import { useSharedState } from '@dc3/data-access/client-state';
import { useGlobalTracking } from '../../../../utils/functional-logging/src/lib/events/use-track-view-page.hook';
import { PageTypes } from '@dc3/utils/functional-logging';
import { useRouter } from 'next/router';

export interface ErrorFallbackProps {
  statusCode?: number | undefined;
  error?: (Error & { statusCode?: number | undefined }) | null | undefined;
  onRetry?: () => void;
  reportable?: boolean;
  showHomeButton?: boolean;
  messageKey?: string;
  icon?: string;
}

export const Error = ({
  error,
  onRetry,
  reportable,
  statusCode,
  showHomeButton,
  messageKey,
  icon,
}: ErrorFallbackProps) => {
  const translate = useI18n();
  const { actions } = useSharedState();
  const router = useRouter();

  const errorMessage = error ? error.message || error.statusCode : statusCode;
  const pageType = errorMessage === 404 ? PageTypes.NOT_FOUND : PageTypes.ERROR;

  useGlobalTracking(pageType, router);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="100%"
    >
      <Box marginBottom={2}>
        <Icon color="secondary" fontSize="large" outlined>
          {icon ?? 'report_problem_outline'}
        </Icon>
      </Box>
      <Box marginBottom={2} textAlign="center" maxWidth="500px">
        <Text
          color="primary"
          component="h1"
          variant="h6"
          value={messageKey ?? 'global.error.somethingWentWrong'}
        />
      </Box>

      {errorMessage && (
        <Alert severity="error">
          <Text color="inherit">{errorMessage}</Text>
        </Alert>
      )}

      <Box
        display="flex"
        justifyContent="space-around"
        flexWrap="nowrap"
        marginY={2}
        minWidth="230px"
      >
        {onRetry && (
          <Button
            color="primary"
            onClick={onRetry}
            startIcon={<Icon>refresh</Icon>}
          >
            {translate('global.error.retry')}
          </Button>
        )}
        {showHomeButton && (
          <Button
            color="primary"
            startIcon={<Icon>home</Icon>}
            onClick={() => (window.location.href = '/')}
          >
            {translate('global.home')}
          </Button>
        )}
      </Box>

      {reportable && (
        <FeedbackButton
          iconMode={false}
          onClick={() => actions.setShowFeedbackDialog(true)}
        />
      )}
    </Box>
  );
};
